<template>
  <div class="elv-transactions-trade-table-list-content">
    <CustomTable
      ref="tableListRef"
      border
      stripe
      show-summary
      :tableData="tableData.list"
      :tableHeader="tableHeader"
      :paginationData="pageData"
      :tableCountNumber="tableData?.filteredCount"
      :tableLoading="tableLoading"
      :summary-method="setSummaries"
      @onChangePage="onChangePage"
      @onChangePageSize="onChangePageSize"
      @row-click="onViewDetail"
    >
      <template #type="{ data }">
        <IconWithTitleCell
          :titleWeight="400"
          :svgName="typeData(data)?.icon"
          :title="typeData(data)?.label ? t(typeData(data)?.label) || '-' : '-'"
        />
      </template>
      <template #datetime="{ data }">
        <DateTimeCell :params="{ data }" bottom-fields="tradeHash" />
      </template>
      <template #baseAsset="{ data }">
        <IconWithTitleCell
          :titleWeight="400"
          :icon="gainOrLossAssetInfo(data).platformIcon"
          :title="gainOrLossAssetInfo(data).name"
        />
      </template>
      <template #positionSide="{ data }">
        <IconWithTitleCell
          :titleWeight="400"
          :svgName="positionSideInfo(data).svgName || ''"
          :title="t(positionSideInfo(data).label) || '-'"
        />
      </template>
      <template #baseAmount="{ data }">
        <TextCell
          class="elv-transactions-trade-table-list-content-base-asset"
          :text="baseAmountText(data).text"
          :title-desc="baseAmountText(data).desc"
          :weight="500"
        />
      </template>
      <template #counterAsset="{ data }">
        <IconWithTitleCell
          :titleWeight="400"
          :icon="data.counterUnderlyingCurrency?.logo"
          :title="data.counterUnderlyingCurrency?.symbol || ''"
        />
      </template>
      <template #counterAmount="{ data }">
        <VerticalTextCell
          :upText="upCounterAmount(data).text"
          :upDesc="upCounterAmount(data).desc"
          :downText="downCounterAmountFC(data).text"
          :downDesc="downCounterAmountFC(data).desc"
          :up-weight="500"
          :align="'right'"
        />
      </template>
      <template #feeAsset="{ data }">
        <IconWithTitleCell
          :titleWeight="400"
          :icon="data.feeUnderlyingCurrency?.logo || ''"
          :title="data.feeUnderlyingCurrency?.showSymbol || '-'"
        />
      </template>
      <template #feeAmount="{ data }">
        <VerticalTextCell
          :upText="upFeeAmountText(data).text"
          :downText="downFeeAmountText(data).text"
          :upDesc="upFeeAmountText(data).desc"
          :downDesc="downFeeAmountText(data).desc"
          :upWeight="500"
        />
      </template>
      <template #category="{ data }">
        <div v-if="!isEmpty(data.journalType)" class="elv-transactions-trade-table-list-content-category">
          <SvgIcon name="report-checkmark" width="14" height="14" fill="#50970B" />{{ data.journalType.name }}
        </div>
        <div v-else class="elv-transactions-trade-table-list-content-category-empty">-</div>
      </template>
      <template #detailTag="{ data }">
        <div class="elv-transactions-table-row__operating">
          <Popover
            trigger="hover"
            placement="bottomLeft"
            overlay-class-name="elv-transactions-table-row__operating-popover"
            :offset="2"
          >
            <SvgIcon width="14" height="14" name="more-dian" class="elv-transactions-table-row__operating-icon" />

            <template #content>
              <div
                class="elv-transactions-table-row__operating-item"
                :style="{ display: 'flex', 'align-items': 'center' }"
                @click="onViewDetail(data)"
              >
                <SvgIcon name="side-panel--close" width="18" height="18" fill="#D0D4D9" />{{ t('button.viewDetail') }}
              </div>
            </template>
          </Popover>
        </div>
      </template>
    </CustomTable>
  </div>
  <DetailOverlay
    ref="detailOverlayRef"
    :is-batch="false"
    :currentData="overlayDrawerData.currentData"
    @onResetList="onResetList"
  />
</template>
<script lang="ts" setup>
import { $t } from '@/i18n/index'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es' // find
import { Popover } from 'ant-design-vue'
import DetailOverlay from './DetailOverlay.vue'
import { positionOptions, tradeType } from '@/config'
import { useEntityStore } from '@/stores/modules/entity'
import CustomTable from '../../../components/CustomTable.vue'
import { ElMessage, type TableColumnCtx } from 'element-plus'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import VerticalTextCell from '@/components/Base/Table/Cell/VerticalTextCell.vue'
import IconWithTitleCell from '@/components/Base/Table/Cell/IconWithTitleCell.vue'
import { formatNumber, formatNumberToSignificantDigits, fieldValueFormat } from '@/lib/utils'

interface SummaryMethodProps<T = any> {
  columns: TableColumnCtx<T>[]
  data: T[]
}

const props = defineProps({
  tableData: {
    type: Object,
    default: () => ({})
  },
  pageData: {
    type: Object,
    require: true,
    default: () => {}
  },
  tableLoading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onResetList', 'onChangePage', 'onChangePageSize'])

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const tableListRef = useTemplateRef('tableListRef')
const detailOverlayRef = useTemplateRef('detailOverlayRef')
const currentData: any = ref({})

const overlayDrawerData: any = reactive({
  type: 'contact',
  drawerTitle: 'Contact',
  currentData: {},
  isBatch: false,
  journalModel: 'add'
})

const tableHeader: any[] = [
  {
    dataIndex: 'type',
    title: $t('common.type'),
    slotName: 'type',
    width: 200
  },
  {
    dataIndex: 'datetime',
    title: $t('common.dateTime'),
    slotName: 'datetime',
    width: 200
  },
  {
    dataIndex: 'baseAsset',
    title: $t('report.Base Asset'),
    slotName: 'baseAsset',
    width: 150
  },
  {
    dataIndex: 'positionSide',
    title: $t('report.positionAndSide'),
    slotName: 'positionSide',
    width: 150
  },
  {
    dataIndex: 'baseAmount',
    title: $t('report.baseAndAmount'),
    slotName: 'baseAmount',
    width: 200,
    headerAlign: 'right'
  },
  {
    dataIndex: 'counterAsset',
    title: $t('report.counterAsset'),
    slotName: 'counterAsset',
    width: 200
  },
  {
    dataIndex: 'counterAmount',
    title: $t('report.counterAndAmount'),
    slotName: 'counterAmount',
    width: 200,
    headerAlign: 'right'
  },
  {
    dataIndex: 'feeAsset',
    title: $t('report.feeAsset'),
    slotName: 'feeAsset',
    width: 200
  },
  {
    dataIndex: 'feeAmount',
    title: $t('report.feeAndAmount'),
    slotName: 'feeAmount',
    width: 200,
    headerAlign: 'right'
  },
  {
    dataIndex: 'category',
    title: $t('title.transactionCategory'),
    slotName: 'category',
    fixed: 'right',
    min_width: 200
  },
  {
    dataIndex: 'detailTag',
    title: '',
    slotName: 'detailTag',
    fixed: 'right',
    width: 44
  }
]

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const typeData = computed(() => {
  return (row: any) => {
    const data: any = tradeType.find((item: any) => item.value === row?.type) || {}
    return data
  }
})

const baseAmountText = computed(() => {
  return (data: any) => {
    if (!data.baseAmount) return { text: '-', desc: '' }
    return {
      text: formatNumber(Number(data?.baseAmount), 2),
      desc: data?.baseAmount || ''
    }
  }
})

const gainOrLossAssetInfo = computed(() => {
  return (data: any) => {
    const assetData = { name: '', icon: '', title: '-', platformIcon: '' }
    if (isEmpty(data.asset?.underlyingCurrency) && isEmpty(data.asset?.derivative)) {
      return assetData
    }
    if (isEmpty(data.asset?.underlyingCurrency)) {
      assetData.name = data.asset?.derivative?.name || '-'
      assetData.title = data.asset?.derivative?.marginAssetCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.derivative?.marginAssetCurrency?.logo || ''
      assetData.platformIcon = data.entityAccount?.platform?.logo || ''
    } else {
      assetData.name = data.asset?.underlyingCurrency?.showSymbol || '-'
      assetData.title = data.asset?.underlyingCurrency?.showSymbol || '-'
      assetData.icon = data.asset?.underlyingCurrency?.logo || ''
      assetData.platformIcon = data.asset?.underlyingCurrency?.logo || ''
    }
    return assetData
  }
})

const upCounterAmount = computed(() => {
  return (data: any) => {
    if (!data?.counterAmount) return { text: '-', desc: '' }
    const value = formatNumberToSignificantDigits(Number(data?.counterAmount), 2, '', false)
    const symbol = data?.counterCurrency?.showSymbol
      ? data?.counterCurrency?.showSymbol
      : (data?.counterUnderlyingCurrency?.showSymbol ?? data?.counterCurrency)
    return {
      text: `${value}\n${symbol}`,
      desc: `${formatNumber(data?.counterAmount, 20) || ''} ${symbol}`
    }
  }
})

const downCounterAmountFC = computed(() => {
  return (data: any) => {
    if (!data?.counterAmountFC) return { text: '-', desc: '' }
    const fcValue = formatNumberToSignificantDigits(
      data?.counterAmountFC,
      2,
      `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
    )

    const descText = `${
      entityDetail?.value.underlyingCurrency?.sign || entityDetail?.value.underlyingCurrency?.showSymbol
    }${formatNumber(data?.counterAmountFC, 20)} `
    return {
      text: fcValue,
      desc: descText
    }
  }
})

const upFeeAmountText = computed(() => {
  return (data: any) => {
    if (!data?.feeAmount) return { text: '-', desc: '' }
    const value = formatNumberToSignificantDigits(Number(data?.feeAmount), 2, '', false)
    const symbol = data?.feeCurrency?.symbol
      ? data?.feeCurrency?.symbol
      : (data?.feeUnderlyingCurrency?.symbol ?? (data?.feeCurrency || ''))
    return {
      text: `${value}\n${symbol}`,
      desc: `${formatNumber(data?.feeAmount, 20) || ''} ${symbol}`
    }
  }
})

const downFeeAmountText = computed(() => {
  return (data: any) => {
    if (!data?.feeAmountFC) return { text: '', desc: '' }
    const fcValue = formatNumberToSignificantDigits(
      data?.feeAmountFC,
      2,
      `${entityDetail?.value.underlyingCurrency?.sign || `${entityDetail?.value.underlyingCurrency?.showSymbol} `}`
    )
    const descText = `${
      entityDetail?.value.underlyingCurrency?.sign || entityDetail?.value.underlyingCurrency?.showSymbol
    }${formatNumber(data?.feeAmountFC, 20) || ''} `
    return {
      text: fcValue,
      desc: descText
    }
  }
})

const positionSideInfo = computed(() => {
  return (data: any) => {
    return positionOptions.find((item) => item.value === data?.positionSide) || { label: '', svgName: '' }
  }
})

/**
 * 打开交易数据详情界面
 * @param data 点击行交易数据
 */
const onViewDetail = (data: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = data
  overlayDrawerData.currentData = data
  detailOverlayRef.value?.onCheckDrawerStatus()
}

/**
 * 更新交易列表数据
 */
const onResetList = () => {
  emit('onResetList')
}

/**
 * 分页器页码改变触发
 * @param page 页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * 分页器每页条数改变触发
 * @param pageSize 每页条数
 */
const onChangePageSize = (pageSize: number) => {
  emit('onChangePageSize', pageSize)
}

/**
 * 交易列表列统计函数
 * @param param table列表每列数据
 */
const setSummaries = (param: SummaryMethodProps) => {
  const { columns } = param
  const sums: string[] = []
  columns.forEach((column, index) => {
    if (index === 0) {
      sums[index] = `${t('common.total')}: ${formatNumber(props.tableData.filteredCount)}`
      return
    }
    if (index === 6) {
      sums[index] = `${fieldValueFormat(
        props.tableData.total?.counterAmountFC,
        {
          dollar: true
        },
        'NUMBER'
      )}`
    }
    if (index === 8) {
      sums[index] = `${fieldValueFormat(
        props.tableData.total?.feeAmountFC,
        {
          dollar: true
        },
        'NUMBER'
      )}`
    }
  })

  nextTick(() => {
    setTimeout(() => {
      const current = tableListRef.value?.$el
        ?.querySelector('.el-table__footer-wrapper')
        ?.querySelector('.el-table__footer')
      const cell = current?.rows?.[0]?.cells
      cell[6].style.textAlign = 'right'
      cell[8].style.textAlign = 'right'
      if (cell[6].firstChild.childNodes.length === 1) {
        const span = document.createElement('p')
        span.innerText = `${t('common.sum')}`
        cell[6].firstChild.appendChild(span)
      }
      if (cell[8].firstChild.childNodes.length === 1) {
        const span = document.createElement('p')
        span.innerText = `${t('common.sum')}`
        cell[8].firstChild.appendChild(span)
      }
    }, 10)
  })
  return sums
}
</script>
<style lang="scss">
.elv-transactions-trade-table-list-content {
  .elv-base-cell-vertical-text-up,
  .elv-base-cell-vertical-text-down {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .elv-transactions-trade-table-list-content-category {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: fit-content;
    padding: 0px 8px;
    background-color: #f1f8ea;
    border-radius: 14px;
    color: #41780c;
    font-size: 11px;

    svg {
      margin-right: 4px;
    }
  }

  .elv-transactions-trade-table-list-content-category-empty {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .elv-transactions-trade-table-list-content-base-asset {
    text-align: right;
  }

  .elv-base-cell-icon-title {
    padding: 12px 0px;
  }
}
</style>
<style lang="scss" scoped>
.elv-transactions-trade-table-list-content {
  height: 100%;
  position: relative;

  .elv-component-custom-table-panel {
    width: 100%;
    height: 100%;
    position: relative;

    :deep(.elv-component-custom-table) {
      .el-table__inner-wrapper {
        .el-table__body-wrapper {
          tr > td {
            &:last-child {
              width: 44px;

              .cell {
                min-width: 44px;
                width: 44px !important;
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        .el-table__footer-wrapper {
          .el-table__footer {
            .el-table__cell:nth-child(1) {
              .cell {
                color: #636b75;
                font-family: 'Plus Jakarta Sans';
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            .el-table__cell:nth-child(7) {
              .cell {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-family: Barlow;
                font-size: 13px;
                font-weight: 500;
                color: #0e0f11;

                p {
                  font-size: 11px;
                  font-weight: 400;
                  color: #838d95;
                  line-height: 8px;
                }
              }
            }

            .el-table__cell:nth-child(9) {
              .cell {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-family: Barlow;
                font-size: 13px;
                font-weight: 500;
                color: #0e0f11;

                p {
                  font-size: 11px;
                  font-weight: 400;
                  color: #838d95;
                  line-height: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
